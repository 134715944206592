import { differenceInYears } from 'date-fns'
import { round } from 'lodash'
import { filter, includes } from 'lodash/fp'
import UAParser from 'ua-parser-js'

import { default as appConfig, default as config } from '../config'
import {
	AppointmentStatus,
	APPOINTMENT_STATUS,
	Exam,
	ExamStatus,
	StrippedExam,
	WorklistExam,
} from '../model/exam'
import { OCTData, RetinalImageData } from '../model/instruments'
import { RemoteInstrumentApi, RemoteMediaApi } from '../model/instrumentsApi'
import { InternalPatient, TeloPatient } from '../model/patient'
import { Role } from '../model/users'

import { inProgressStatus } from './appointments'
import { FileExtension } from './constants'
import { examEndedStatus } from './exams'

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[]

export const capitalizeFirstLetter = (word: string) =>
	word.charAt(0).toUpperCase() + word.slice(1)

const selectableGenders = ['male', 'female', 'na', 'other']
type SelectableGender = (typeof selectableGenders)[number]

export const isGender = (value: string): value is SelectableGender => {
	return selectableGenders.includes(value as SelectableGender)
}

export const getGenderInitial = (gender: string): string => {
	switch (gender) {
		case 'male':
			return 'patient.genderMaleInitial'
		case 'female':
			return 'patient.genderFemaleInitial'
		case 'na':
			return 'patient.notAvailable'
		case 'other':
			return 'patient.genderOtherInitial'
		default:
			return gender
	}
}

export const extractPatientName = (patient?: {
	name: string
	surname: string
}): string => {
	return patient ? [patient.name, patient.surname].join(' ') : ''
}

export const extractLTName = (exam: Exam): string => {
	const name = (
		exam.localTechnicianName ? exam.localTechnicianName[0] : ''
	).toUpperCase()
	const surname = exam.localTechnicianSurname || ''
	return `LT ${name}. ${surname}`
}

export const extractODName = (exam: Exam): string => {
	if (!exam.endDoctorFullname) {
		return '-'
	}
	const [name, surname] = exam.endDoctorFullname.split(' ')
	const nameCapitalize = capitalizeFirstLetter(name)
	const surnameCapitalize = capitalizeFirstLetter(surname)
	const initialLetterName = nameCapitalize[0]

	return `Dr. ${initialLetterName}. ${surnameCapitalize}`
}

export const getPatientAge = (
	patient?: TeloPatient | InternalPatient,
	fromDate = new Date(),
) => {
	if (!patient?.birthDate) {
		return null
	}

	let birthDate = patient.birthDate as Date | string
	if (typeof birthDate === 'string') {
		if (birthDate.includes('T')) {
			let [date] = birthDate.split('T')
			birthDate = date
		}
		birthDate = new Date(birthDate + 'T00:00:00')
	}
	return differenceInYears(fromDate, birthDate)
}

export const getLastExamByStatus = ({
	exams,
	statusToFilter,
}: {
	exams: (Exam | StrippedExam)[]
	statusToFilter: ExamStatus[]
}): Exam | StrippedExam | undefined => {
	const filteredExams = exams
		.filter(
			(exam: Exam | StrippedExam) =>
				exam.status === 'Ended' || statusToFilter.includes(exam.status),
		)
		.filter((exam: Exam | StrippedExam) =>
			exam.history
				.slice()
				.reverse()
				.some(({ currentStatus }) => statusToFilter.includes(currentStatus)),
		)

	const sortedArray = filteredExams.sort(
		(a, b) =>
			Date.parse((b.checkedInAt || b.externalAppointment.date).toString()) -
			Date.parse((a.checkedInAt || a.externalAppointment.date).toString()),
	)

	const firstElement = sortedArray.length ? sortedArray[0] : undefined

	return firstElement
}

export const getRoleCode = (role: Role) => {
	switch (role) {
		case 'Technician':
			return 'LT'
		case 'Refractionist':
			return 'RT'
		case 'Doctor':
			return 'RD'
		default:
			return ''
	}
}
export const convertDigitToWord = (digit: number): string => {
	switch (digit) {
		case 0:
			return 'zero'
		case 1:
			return 'one'
		case 2:
			return 'two'
		case 3:
			return 'three'
		case 4:
			return 'four'
		case 5:
			return 'five'
		case 6:
			return 'six'
		default:
			return 'many'
	}
}

export const isString = (s: unknown): s is string => typeof s === 'string'

export const truncate = (input: string, length: number): string =>
	input && input.length > length ? `${input.substring(0, length)}...` : input

export const checkStoreIdFormat = (storeId: string, checkpoint: string) => {
	if (storeId.length !== 24) {
		const errorMsg = `The store id: ${storeId} seems to be wrong at checkpoint: ${checkpoint}`
		console.error(errorMsg)
	}
}

const uaParser = new UAParser()

export const isIOS =
	(/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
	!(window as any).MSStream

export const isSafari = /^((?!chrome|android).)*safari/i.test(
	navigator.userAgent,
)

export const getOs = () => {
	uaParser.setUA(window.navigator.userAgent)
	return uaParser.getOS()
}

export const isAndroid = () => {
	return getOs().name === 'Android'
}

export const isMobile = () => {
	const ios = getOs().name === 'iOS'
	return ios || isAndroid()
}

interface AppointmentOrExam {
	status: ExamStatus
	date?: string
	createdAt?: Date
	checkedInAt?: Date
	history?: {
		currentStatus?: string
		prevStatus?: string
		statusUpdatedAt?: Date
	}[]
}

export const sortAppointmentOrExam = <T extends AppointmentOrExam>(
	appointment: T[],
) => {
	const toStartItems = (exam: AppointmentOrExam) =>
		APPOINTMENT_STATUS.includes(exam.status as AppointmentStatus)

	const inProgressItems = (exam: AppointmentOrExam) =>
		inProgressStatus.includes(exam.status)
	const doneItems = (exam: AppointmentOrExam) =>
		examEndedStatus.includes(exam.status)
	const asc = (exam1: AppointmentOrExam, exam2: AppointmentOrExam) =>
		new Date(exam1.date || exam1.checkedInAt || 0).getTime() -
		new Date(exam2.date || exam2.checkedInAt || 0).getTime()

	const desc = (exam1: AppointmentOrExam, exam2: AppointmentOrExam) => {
		if (exam1.history && exam2.history) {
			return (
				new Date(
					exam2.history[exam2.history.length - 1].statusUpdatedAt || 0,
				).getTime() -
				new Date(
					exam1.history[exam1.history.length - 1].statusUpdatedAt || 0,
				).getTime()
			)
		} else {
			return (
				new Date(exam2.date || exam2.checkedInAt || 0).getTime() -
				new Date(exam1.date || exam1.checkedInAt || 0).getTime()
			)
		}
	}
	const sortedLists = appointment.filter(inProgressItems).sort(asc)
	const sortedAppointments = appointment
		.filter(toStartItems)
		.sort(asc)
		.concat(sortedLists)
		.concat(appointment.filter(doneItems).sort(desc))

	return sortedAppointments
}

export type BaseExam = Pick<Exam, 'history' | 'externalAppointment'>

export const sortExamsByDescOrder = <E extends BaseExam>(
	exam1: E,
	exam2: E,
) => {
	if (exam1.history?.length > 0 && exam2.history?.length > 0) {
		return (
			new Date(
				exam2.history[exam2.history.length - 1].statusUpdatedAt || 0,
			).getTime() -
			new Date(
				exam1.history[exam1.history.length - 1].statusUpdatedAt || 0,
			).getTime()
		)
	}

	return (
		new Date(exam2.externalAppointment.date).getTime() -
		new Date(exam1.externalAppointment.date).getTime()
	)
}

export const sortWorklistExams = (exams: WorklistExam[]) => {
	const toStartItems = (exam: WorklistExam) => exam.status === 'Planned'

	const inProgressItems = (exam: WorklistExam) =>
		inProgressStatus.includes(exam.status)
	const doneItems = (exam: WorklistExam) =>
		examEndedStatus.concat(['Canceled']).includes(exam.status)
	const asc = (exam1: WorklistExam, exam2: WorklistExam) =>
		new Date(exam1.externalAppointment.date).getTime() -
		new Date(exam2.externalAppointment.date).getTime()

	const sortedLists = exams.filter(inProgressItems).sort(asc)
	const sortedExams = exams
		.filter(toStartItems)
		.sort(asc)
		.concat(sortedLists)
		.concat(exams.filter(doneItems).sort(sortExamsByDescOrder))

	return sortedExams
}

export const filterInstrumentMedia = filter<RemoteMediaApi>(
	includes(FileExtension.dcm),
)

export const getFilteredInstrumentByMediaFormat = <
	I extends RemoteInstrumentApi,
>(
	instrumentData: I,
): I => ({
	...instrumentData,
	L: {
		...instrumentData.L,
		media: filterInstrumentMedia(instrumentData.L.media),
	},
	R: {
		...instrumentData.R,
		media: filterInstrumentMedia(instrumentData.R.media),
	},
})

export const filterRIDataByType = (
	data: RetinalImageData,
	format: 'image' | 'dcm',
): RetinalImageData => {
	const imageTypes = ['jpg', 'jpeg', 'webp', 'png']
	const res = {
		OD: {
			data: [],
			note: data.OD.note,
		},
		OS: { data: [], note: data.OS.note },
	} as RetinalImageData
	if (format === 'image') {
		res.OD.data = data.OD.data.filter(d => imageTypes.includes(d.format))
		res.OS.data = data.OS.data.filter(d => imageTypes.includes(d.format))
	} else {
		res.OD.data = data.OD.data.filter(d => d.format === format)
		res.OS.data = data.OS.data.filter(d => d.format === format)
	}
	return res
}

export const filterOCTDataByType = (
	data: OCTData,
	format: 'image' | 'dcm',
): OCTData => {
	const imageTypes = ['jpg', 'jpeg', 'webp', 'png']
	const res = {
		OD: {
			...data.OD,
			media: [],
		},
		OS: {
			...data.OS,
			media: [],
		},
		unableToPerformExam: data.unableToPerformExam,
		unableToPerformExamReason: data.unableToPerformExamReason,
	} as OCTData
	if (format === 'image') {
		res!.OD!.media = (data?.OD?.media || []).filter(d =>
			imageTypes.includes(d.format),
		)
		res!.OS!.media = (data?.OS?.media || []).filter(d =>
			imageTypes.includes(d.format),
		)
	} else {
		res!.OD!.media = (data?.OD?.media || []).filter(d => d.format === format)
		res!.OS!.media = (data?.OS?.media || []).filter(d => d.format === format)
	}
	return res
}

export const isNotUndefined = <T>(x: T | undefined): x is T => x !== undefined

export const isNotNull = <T>(x: T | null): x is T => x !== null

export const isNotFalse = <T>(x: T | false): x is T => x !== false

export const getResultByCondition = <T>(
	conditionToResultMap: { condition: boolean; result: T }[],
	defaultResult: T,
): T => {
	return (
		conditionToResultMap.find(({ condition }) => condition)?.result ||
		defaultResult
	)
}

export const roundToTwoDecimals = (number: number): number => round(number, 2)

export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

export const ATTACHMENT_TYPES = [
	'documents',
	'consents',
	'medicationLists',
	'referralLetters',
	'dotLetters',
	'diagnosticImaging',
	'lasikCoManagementForms',
	'glaucomaReports',
	'retinaReports',
	'cataractCoManagementForms',
	'visitSummaryLetters',
	'pharmacyRefillRequests',
	'patientInvoices',
	'video',
	'image',
]

export const isShowroom = appConfig.environment === 'SHOWROOM'
export const isDevelopment = appConfig.environment === 'DEVELOPMENT'
export const isTest = appConfig.environment === 'TEST'
export const isQA = appConfig.environment === 'QA'
export const showDebugMode = [
	'localhost',
	'DEVELOPMENT',
	'TEST',
	'QA',
].includes(appConfig.environment)

export const getMaxFileSize = (v: number) => v * 1048576

export const getError = (el: object | string, matchKeys?: string[]) => {
	const keyToMatch = matchKeys || ['error']
	let toMatch = el
	if (isString(el)) {
		try {
			toMatch = JSON.parse(el)
		} catch {
			return false
		}
	}

	const keys = Object.keys(toMatch).filter(k => keyToMatch.includes(k))

	return keyToMatch.length === keys.length ? toMatch : false
}

export const executeAfterSetField = async (fun: () => Promise<void>) => {
	await new Promise<void>(res => {
		setTimeout(async () => {
			await fun()
			res()
		})
	})
}

export const returnFormattedValueForAxis = (value: string): string => {
	const num = Number(value)
	// format 0 value
	if (String(num).padStart(3, '0') === '000') {
		return ''
	}
	// format other values
	if (!num) {
		return value
	} else {
		return String(num).padStart(3, '0')
	}
}

export const isNumber = (value: string | number): boolean =>
	value != null && value !== '' && !isNaN(Number(value.toString()))

export const GetExternalIdentifierSource = () => {
	let source = 'TAB-C'

	switch (config.region) {
		case 'NA':
			source = 'TAB-C'
			break
		case 'APAC':
			source = 'FOCLUM'
			break
		case 'EMEA':
		case 'GEMINI':
			source = 'FHIR'
			break
		default:
			break
	}
	return source
}
