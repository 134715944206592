import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Panel, PanelWithStoreName } from '../model/panel'
import { unversionedApiUrl } from './common'

export const DOCTOR_PANELS_TAG = 'doctor-panels'

export const panelApi = createApi({
	reducerPath: 'panelApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	tagTypes: [DOCTOR_PANELS_TAG],
	endpoints: builder => ({
		getDoctorPanels: builder.query<PanelWithStoreName[], void>({
			query: () => ({
				url: `/v1/panels/doctor`,
			}),
			providesTags: [DOCTOR_PANELS_TAG],
		}),
		setDoctorActiveInPanel: builder.mutation<
			Panel,
			{ panelId: string; active: boolean }
		>({
			query: ({ panelId, active }) => ({
				url: `/v1/panels/${panelId}`,
				method: 'PATCH',
				body: JSON.stringify({ active }),
			}),
			invalidatesTags: [DOCTOR_PANELS_TAG],
		}),
	}),
})

export const { useGetDoctorPanelsQuery, useSetDoctorActiveInPanelMutation } =
	panelApi
